import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { InitialDataResolver } from "app/app.resolvers";
import { LayoutComponent } from "app/layout/layout.component";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { Route } from "@angular/router";
import { CompleteRegistrationGuard } from "./core/auth/guards/complete-registration.guard";

export const appRoutes: Route[] = [
  { path: "", pathMatch: "full", redirectTo: "dashboard" },

  { path: "signed-in-redirect", pathMatch: "full", redirectTo: "dashboard" },

  {
    path: "",
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "confirmation",
        loadChildren: () =>
          import("app/modules/auth/confirmation/confirmation.module").then(
            (m) => m.ConfirmationModule
          ),
      },
      {
        path: "confirmation-required",
        loadChildren: () =>
          import(
            "app/modules/auth/confirmation-required/confirmation-required.module"
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: "forgot-password",
        loadChildren: () =>
          import(
            "app/modules/auth/forgot-password/forgot-password.module"
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: "reset-password",
        loadChildren: () =>
          import("app/modules/auth/reset-password/reset-password.module").then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: "sign-in",
        loadChildren: () =>
          import("app/modules/auth/sign-in/sign-in.module").then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: "sign-up",
        loadChildren: () =>
          import("app/modules/auth/sign-up/sign-up.module").then(
            (m) => m.AuthSignUpModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-out",
        loadChildren: () =>
          import("app/modules/auth/sign-out/sign-out.module").then(
            (m) => m.AuthSignOutModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "verify",
        loadChildren: () =>
          import("app/modules/main/verify/verify.module").then(
            (m) => m.VerifyModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard, CompleteRegistrationGuard],
    canActivateChild: [AuthGuard, CompleteRegistrationGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: "settings",
        loadChildren: () =>
          import("app/modules/admin/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
      },
    ],
  },
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "complete-registration",
        loadChildren: () =>
          import(
            "app/modules/main/complete-registration/complete-registration.module"
          ).then((m) => m.CompleteRegistrationModule),
      },
    ],
  },
  // Main routes
  {
    path: "",
    canActivate: [AuthGuard, CompleteRegistrationGuard],
    canActivateChild: [AuthGuard, CompleteRegistrationGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("app/modules/main/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "sites",
        loadChildren: () =>
          import("app/modules/main/sites/sites.module").then(
            (m) => m.SitesModule
          ),
      },
      {
        path: "actors",
        loadChildren: () =>
          import("app/modules/main/actors/actors.module").then(
            (m) => m.ActorsModule
          ),
      },
      {
        path: "resources",
        loadChildren: () =>
          import("app/modules/main/resources/resources.module").then(
            (m) => m.ResourcesModule
          ),
      },
      {
        path: "inspections",
        loadChildren: () =>
          import("app/modules/main/inspections/inspections.module").then(
            (m) => m.InspectionsModule
          ),
      },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "**", redirectTo: "dashboard" },
    ],
  },


];

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CommonModule } from "@angular/common";
import { FuseAlertModule } from "@fuse/components/alert";
import { FuseCardModule } from "@fuse/components/card";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { NgModule } from "@angular/core";
import { NoRouteComponent } from "./components/no-route/no-route.component";
import { MediaPickerComponent } from "./components/media-picker/media-picker.component";
import { DocumentsPickerComponent } from "./components/documents-picker/documents-picker.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { FileDragNDropDirective } from "./file-drag-n-drop.directive";
import { MatDividerModule } from "@angular/material/divider";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  imports: [
    MatButtonModule,
    MatIconModule,
    FuseCardModule,
    FuseAlertModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatDividerModule, MatDialogModule
  ],
  exports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [
    NoRouteComponent,
    MediaPickerComponent,
    DocumentsPickerComponent,
    FileDragNDropDirective,
  ],
})
export class SharedModule {}

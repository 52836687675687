import { BehaviorSubject, Observable, map, of } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Injectable, isDevMode } from "@angular/core";
import { User } from "app/core/user/user.types";

@Injectable({
  providedIn: "root",
})
export class UserService {
  //private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  private _user: BehaviorSubject<User> = new BehaviorSubject<User>({} as User);
  private debug = isDevMode();

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    return of(this._user.value);

    // return this._httpClient.get<User>('api/common/user').pipe(
    //     tap((user) => {
    //         this._user.next(user);
    //     })
    // );
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {

    return of(user);

    // return this._httpClient.patch<User>("api/common/user", { user }).pipe(
    //   map((response) => {
    //     this._user.next(response);
    //   })
    // );
  }
}

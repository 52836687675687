import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BSON } from "realm-web";

@Component({
  selector: "app-documents-picker",
  templateUrl: "./documents-picker.component.html",
  styleUrls: ["./documents-picker.component.scss"],
})
export class DocumentsPickerComponent {
  public files: {
    blob: string; // base64 string
    filename: string;
    mimetype: string;
    size: number;
    handle: string;
    url: string;
  }[] = [];
  public errorMessage: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DocumentsPickerComponent>
  ) {}

  onFileChange(pFileList: File[]) {
    if (this.files.length + pFileList.length > 10) {
      this.errorMessage = "Puoi caricare un massimo di 10 file.";
      return;
    }

    const validTypes = [
      "image/",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    for (const file of pFileList) {
      if (!validTypes.some((type) => file.type.startsWith(type))) {
        this.errorMessage = "Tipo di file non supportato.";
        continue;
      }

      if (file.size > 10 * 1024 * 1024) {
        this.errorMessage = "Ogni file deve essere inferiore a 10MB.";
        continue;
      }

      this.processFile(file);
    }
  }

  private processFile(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const arrayBufferReader = new FileReader();
      arrayBufferReader.onload = (arrayBufferEvent: any) => {
        const buffer = arrayBufferEvent.target.result;
        const blob = new Blob([buffer], { type: file.type });
        const handle = new BSON.ObjectId();
        const url = URL.createObjectURL(blob);

        this.convertBlobToBase64(blob)
          .then((base64) => {
            this.files.push({
              blob: base64,
              filename: file.name,
              mimetype: file.type,
              size: file.size,
              handle: handle.toString(),
              url: url,
            });
            this.errorMessage = null;
          })
          .catch((error) => {
            console.error("Errore nella conversione del blob in base64", error);
            this.errorMessage = "Errore nella conversione del file.";
          });
      };
      arrayBufferReader.readAsArrayBuffer(file);
    };
    reader.readAsDataURL(file);
  }

  deleteFromArray(index: number) {
    URL.revokeObjectURL(this.files[index].url);
    this.files.splice(index, 1);
    this.errorMessage = null;
    console.log(this.files);
  }

  confirm() {
    const filesToUpload = this.files.map((file) => {
      return {
        ...file,
        blob: this.base64ToBinary(file.blob), // Converti base64 in BSON.Binary
      };
    });
    this.dialogRef.close(filesToUpload);
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(base64data.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private base64ToBinary(base64: string): BSON.Binary {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new BSON.Binary(bytes);
  }
}

import { Injectable, isDevMode } from '@angular/core';
import { RealmService } from 'app/core/auth/realm.service';
import { environment } from 'environments/environment';
import { BSON } from 'realm-web';
import { Profile } from '../models/profile';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private debug = isDevMode();

  private get _profileCollection(): Realm.Services.MongoDB.MongoDBCollection<any> {
    return this._realmSvc.accountDb.collection("profile");
  }

  constructor(private _realmSvc: RealmService) {
  }

  // async addPartition(_partition: string, write: boolean) {
  //   if (write) {
  //     const _ = await this._profileCollection.updateOne({}, {
  //       $push: { "writePartitions": _partition }
  //     });
  //     if(this.debug) console.log("[AccountService] - addPartition - added to writePartitions: ", _partition);

  //     const __2 = await this._realmSvc.currentUser.refreshCustomData();
  //   } else {
  //     const __1 = await this._profileCollection.updateOne({}, {
  //       $push: { "readPartitions": _partition }
  //     });
  //     if(this.debug) console.log("[AccountService] - addPartition - added to readPartitions: ", _partition);

  //     const __3 = await this._realmSvc.currentUser.refreshCustomData();
  //   }
  // }

  // async removePartition(_partition: string, write: boolean) {
  //   if (write) {
  //     const _ = await this._profileCollection.updateOne({}, {
  //       $pull: { "writePartitions": _partition }
  //     });
  //     if(this.debug) console.log("[AccountService] - removePartition - removed from writePartitions: ", _partition);

  //     return await this._realmSvc.currentUser.refreshCustomData();
  //   } else {
  //     const __1 = await this._profileCollection.updateOne({}, {
  //       $pull: { "readPartitions": _partition }
  //     });
  //     if(this.debug) console.log("[AccountService] - removePartition - removed from readPartitions: ", _partition);

  //     return await this._realmSvc.currentUser.refreshCustomData();
  //   }
  // }

  getProfile(): Promise<Profile> {
    return this._profileCollection.findOne({},
      { projection: { name: 1, phone: 1, language: 1, company: 1, pictureUrl: 1 } }
    );
  }

  /**
   * Update the profile of the current user with the new data passed in profile param.
   * @param {any} profile The updated profile to save 
   * @todo add alert for the user to know wht's going on.
   */
  updateProfile(profile: Profile) {

    return this._profileCollection.updateOne({}, { $set: profile });


    // let profileId = new BSON.ObjectId(this._realmSvc.currentUser.customData._id as string);
    // if (this.debug) {
    //   console.log("[AccountService] - updateProfile - profileId: ", profileId);
    // }

    // try {

    //   aw

    //   const updatedProfile = await this._profileCollection.findOneAndUpdate({ _id: profile._id }, { $set: profile });
    //   if (this.debug) console.log("[AccountService] - updateProfile - result: ", updatedProfile);
    //   return updatedProfile;
    // } catch (error) {
    //   if (this.debug) console.log("[AccountService] - updateProfile - error: ", error);
    //   throw error;
    // }

  }

  refreshProfile() {
    return this._realmSvc.currentUser.refreshCustomData();
  }

}

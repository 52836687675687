import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { UserComponent } from "app/layout/common/user/user.component";
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [UserComponent],
  imports: [
    TranslocoModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    SharedModule,
  ],
  exports: [UserComponent],
})
export class UserModule { }

import { Injectable, isDevMode } from "@angular/core";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { FuseLoadingService } from "@fuse/services/loading";
import { TranslocoService } from "@ngneat/transloco";
import { RealmService } from "app/core/auth/realm.service";
import { UserService } from "app/core/user/user.service";
import { User } from "app/core/user/user.types";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SyncService {
  private readonly debug = isDevMode();

  private _profileSbj = new Subject<boolean>();

  private _profileWatcher: AsyncGenerator<any>;

  private get _profileCollection(): Realm.Services.MongoDB.MongoDBCollection<any> {
    return this._realmSvc.accountDb.collection("profile");
  }

  get profile$() {
    return this._profileSbj.asObservable();
  }

  constructor(
    private _realmSvc: RealmService,
    private _fuseLoadingService: FuseLoadingService,
    private _translocoSvc: TranslocoService,
    private _userService: UserService
  ) {}

  async initAfterLogin() {
    console.log(this._realmSvc.currentUser.customData.language);
    await this.initProfileWatcher().finally;
  }

  private async initProfileWatcher() {
    if (this._profileWatcher) {
      this._profileWatcher.return(undefined);
    }

    this._profileWatcher = this._profileCollection.watch({
      operationType: "update",
    });

    for await (const change of this._profileWatcher) {
      if (this.debug)
        console.log("[SiteService] - initProfileWatcher - change: ", change);
      this._fuseLoadingService.show();
      await new Promise((resolve) => setTimeout(resolve, 900));
      this._fuseLoadingService.hide();
      const update = change as Realm.Services.MongoDB.UpdateEvent<any>;
      //const partitionUpdated = Object.keys(update.updateDescription.updatedFields).find(field => field.startsWith("read") || field.startsWith("write"));

      //if (partitionUpdated) {
      const customData = await this._realmSvc.currentUser.refreshCustomData();
      if(this.debug) console.log("[SiteService] - initProfileWatcher - customData: ", customData);
      let user: User = {
         id: "" + customData.userId,
         name: "" + customData.name,
         email: "" + customData.email,
         avatar: "" + customData.pictureUrl,
         status: "online",
       };
       this._userService.user = user;

      this._translocoSvc.setActiveLang(
        this._realmSvc.currentUser.customData.language as any
      );
      //TODO: Impostare locale su transloco service

      this._profileSbj.next(true);
      //}
    }
  }
}

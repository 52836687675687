import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from "@angular/router";
import { RealmService } from "../realm.service";

@Injectable({
  providedIn: "root",
})
export class CompleteRegistrationGuard
  implements CanActivate, CanActivateChild
{
  constructor(private _realmSvc: RealmService, private _router: Router) {}

  async canActivate(): Promise<UrlTree | boolean>{
    if (
      this._realmSvc.currentUser.customData.name !=
      this._realmSvc.currentUser.customData.email
    ) {
      return true;//this._router.parseUrl("/dashboard");
    } else {
      return this._router.parseUrl("/complete-registration");
    }
  }

  async canActivateChild(): Promise<UrlTree | boolean> {
    if (
      this._realmSvc.currentUser.customData.name !=
      this._realmSvc.currentUser.customData.email
    ) {
      return true; //this._router.parseUrl("/dashboard");
    } else {
      return this._router.parseUrl("/complete-registration");
    }
  }
}

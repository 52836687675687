import { Injectable, isDevMode } from "@angular/core";
import { Observable, from, map, of, throwError, catchError } from "rxjs";

import { RealmService } from "./realm.service";
import { User } from "./../user/user.types";
import { UserService } from "app/core/user/user.service";
import { SyncService } from "app/shared/services/sync.service";

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private debug = isDevMode();
  /**
   * Constructor
   */
  constructor(
    private _userService: UserService,
    private _realmSvc: RealmService,
    private _syncSvc: SyncService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    //localStorage.setItem("accessToken", token);
  }

  get accessToken(): string {
    return this._realmSvc.currentUser?.accessToken
    //return localStorage.getItem("accessToken") ?? "";
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return from(this._realmSvc.sendResetPasswordEmail(email));
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(
    token: string,
    tokenId: string,
    password: string
  ): Observable<any> {
    return from(this._realmSvc.resetPassword(token, tokenId, password));
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(loginInfo: { email: string; password: string, rememberMe }): Observable<any> {
    // Throw error, if the user is already logged in

    if (this.debug) console.log("[AuthService] - signIn - loginInfo: ", loginInfo);

    let credentials = { email: loginInfo.email, password: loginInfo.password };

    if (this._realmSvc.currentUser && this._realmSvc.currentUser.isLoggedIn) {
      throwError(() => new Error("User is already logged in."));
    }

    return this._realmSvc.signIn(credentials).pipe(
      // tap((realm_user) => {
      //   this.accessToken = realm_user.accessToken;
      //   this._authenticated = true;
      // }),
      map((realm_user) => {
        let user: User = {
          id: "" + realm_user.id,
          name: "" + realm_user.customData.name,
          email: "" + realm_user.profile.email,
          avatar: "" + realm_user.customData.pictureUrl,
          status: "online",
        };
        this._authenticated = true;
        this._userService.user = user;
        this._syncSvc.initAfterLogin();
        return user;
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token

    if (!this._realmSvc.currentUser || !this._realmSvc.currentUser?.isLoggedIn) {
      this._authenticated = false;
      return of(false);
    } else {
      this._authenticated = true;
      let realm_user = this._realmSvc.currentUser;
      let user: User = {
        id: "" + realm_user.id,
        name: "" + realm_user.customData.name,
        email: "" + realm_user.profile.email,
        avatar: "" + realm_user.customData.pictureUrl,
        status: "online",
      };
      this._userService.user = user;
      this._syncSvc.initAfterLogin();
      return of(true);
    }

  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    //localStorage.removeItem("accessToken");

    this._realmSvc.currentUser.logOut();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    email: string;
    password: string;
  }): Observable<any> {
    this._realmSvc.signUp(user);
    return of(true);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  // unlockSession(credentials: {
  //   email: string;
  //   password: string;
  // }): Observable<any> {
  //   this._realmSvc.currentUser.logOut();

  //   return this.signIn(credentials);

  //   //return this._httpClient.post("api/auth/unlock-session", credentials);
  // }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // // Check the access token availability
    // if (!this.accessToken) {
    //   return of(false);
    // }

    // // Check the access token expire date
    // if (AuthUtils.isTokenExpired(this.accessToken)) {
    //   return of(false);
    // }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken().pipe(
      catchError(error => {
        if (this.debug) console.log("[AuthService] - check - error: ", error);
        return of(false);
      })

    );
  }
}

<div style="display: flex; justify-content: space-between; align-items: center;">
    <h2 mat-dialog-title style="margin-bottom: 0;">Carica immagini</h2>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<div style="padding: 15px; max-width: 100%; box-sizing: border-box;">
    <div>
        <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
            <div class="text-wrapper">
                <div class="centered">
                    <input type="file" name="file" id="file" (change)="onFileChange($event.target.files)"
                        accept="image/*" multiple>
                    <label for="file">
                        <span class="textLink">
                            Seleziona il file
                        </span>
                        <span style="display: block; margin-top: 15px;"> o trascinalo qui!</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="files && files.length > 0" class="files-section">
        <hr>
        <div class="file-list">
            <div class="fileItem" *ngFor="let f of files; let ind = index"
                [style.backgroundImage]="'url(' + f.url + ')'">
                <div (click)="deleteFromArray(ind);">
                    <mat-icon class="fileItemIcon">clear</mat-icon>
                </div>
                <div class="fileItemText">
                    <span>{{f.filename}}</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="errorMessage" class="error-message">
        {{ errorMessage }}
    </div>
</div>

<mat-dialog-actions style="justify-content: flex-end;">
    <button color="accent" mat-flat-button (click)="confirm()">
        Conferma
    </button>
</mat-dialog-actions>
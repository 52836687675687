<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall"
  style="background-color: #092556 !important; color: white !important">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- User -->
    <div style="
        position: relative;
        color: white;
        top: 10px;
        left: 10px;
        font-weight: 200 !important;
        font-size: 8px !important;
        width: fit-content;
      ">
      <div style="font-weight: 200 !important; font-size: 12px !important">
        {{ environment.versioning.version }}
      </div>
      <div *ngIf="debug" style="font-weight: 200 !important; font-size: 12px !important">
        {{ environment.versioning.lastRevisionDate }}
      </div>
      <div *ngIf="debug" style="font-weight: 200 !important; font-size: 12px !important">
        {{ environment.versioning.environment }}
      </div>
    </div>
    <div class="flex flex-col items-center w-full p-4">
      <div class="flex flex-col items-center justify-center w-full mt-6">
        <div style="height: 5vh !important"></div>

        <div class="flex items-center justify-center">
          <img src="assets/images/logo/logo-white.svg" style="height: 150px !important" />
        </div>
        <div style="height: 15vh !important"></div>
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'" style="color: #0064eb"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <!-- <search [appearance]="'bar'"></search> -->

      <button *ngIf="licenseType == 'Free'" mat-icon-button matTooltip="Acquista una licenza" (click)="buyLicense()">
        <mat-icon [svgIcon]="'heroicons_outline:shopping-cart'" style="color: #0064eb"></mat-icon>
      </button>

      <button *ngIf="licenseType == 'Standard'" mat-icon-button matTooltip="Hai una licenza attiva"
        style="cursor: default">
        <mat-icon [svgIcon]="'heroicons_outline:star'" style="color: #0064eb"></mat-icon>
      </button>

      <button mat-icon-button (click)="goToHelpdesk()">
        <mat-icon [svgIcon]="'heroicons_outline:question-mark-circle'" style="color: #0064eb"></mat-icon>
      </button>
      <!-- <languages></languages> -->
      <fuse-fullscreen> </fuse-fullscreen>
      <notifications></notifications>
      <user [showAvatar]="true"></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto" style="background-color: #f6f5f8 !important">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"> </router-outlet>
  </div>

  <!-- <div class="grid grid-cols-10 gap-4 place-content-center"
    style="width: 100%; height: 40px; background-color: #EEEDF2;">
    <div class="col-span-3"><span class="font-medium text-secondary truncate" style="margin-left: 30px">&copy;
        {{currentYear}}
        Site
        Assistant. All Rights
        Reserved.</span></div>
  </div> -->
</div>

<!-- Quick chat -->
<ng-container *transloco="let t">
  <!-- Button -->
  <button mat-stroked-button [matMenuTriggerFor]="userActions" style="padding: 0 5px !important; border: 1px solid #0064eb !important;">
    <span class="relative">
      <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user.avatar" [src]="user.avatar" />
      <mat-icon *ngIf="!showAvatar || !user.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
    <div class="hidden sm:block" style="width: 7px !important"></div>
    <div class="hidden sm:block">
      <span class="mt-1.5 text-md font-medium" style="color: #0064eb;" *ngIf="userName">{{ user?.name }}
      </span>
      <span class="mt-1.5 text-md font-medium" *ngIf="!userName">{{ user?.email }}
      </span>
    </div>
  </button>

  <!-- User status:
        'bg-green-500': user.status === 'online',
        'bg-amber-500': user.status === 'away',
        'bg-red-500': user.status === 'busy',
        'bg-gray-400': user.status === 'not-visible' -->

  <mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item disabled>
      <span class="flex flex-col leading-none">
        <span style="color: black !important;">{{t('hai-effettuato-l-accesso-come')}}</span>
        <span class="mt-1.5 text-md font-medium place-self-center" style="color: black !important;">{{ user.email
          }}</span>
      </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <!--   <button mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>Profile</span>
  </button> -->
    <button mat-menu-item (click)="settings()">
      <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
      <span>{{t('impostazioni')}}</span>
    </button>

    <!-- <button mat-menu-item [matMenuTriggerFor]="userStatus">
    <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
    <span>Status</span>
  </button> -->

    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
      <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
      <span>{{t('esci')}}</span>
    </button>
  </mat-menu>

  <!--   <mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('online')">
      <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
      <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
      <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
      <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
      <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
      <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
      <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
      <span>Invisible</span>
    </button>
  </mat-menu> -->
</ng-container>
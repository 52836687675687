import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { AuthService } from "app/core/auth/auth.service";
import { NgModule } from "@angular/core";
import { RealmService } from "./realm.service";

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AuthService,
    RealmService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // },
  ],
})
export class AuthModule { }

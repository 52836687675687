// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  realmAppId: "site-assistant-dev-xbklw",
  realmDb: "sias",
  realmAccounts: "accounts",
  filestackKey: "AcmIKJSUGTpSuoin16hGsz",
  noderedKey: "e9409ebeed06662fec98e36b49c5349a",
  rptServerUrl: "http://report-dev.siteassistant.it:1880/",
  checkTokenSecret: "gTt91!bMN2pxf6oek^2iX!",
  versioning: {
    version: "Site Assistant v1.1.4",
    lastRevisionDate: "Rev.  14042023",
    environment: "Env. DEVELOPMENT",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

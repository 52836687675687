import { Injectable, isDevMode } from "@angular/core";
import { Observable, of, ReplaySubject, tap } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Navigation } from "app/core/navigation/navigation.types";
import { cloneDeep } from "lodash";
import { FuseNavigationItem } from "@fuse/components/navigation";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> =
    new ReplaySubject<Navigation>(1);
  private debug = isDevMode();

  readonly links: FuseNavigationItem[] = [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "basic",
      icon: "siteassistant:dashboard",
      link: "/dashboard",
    },
    {
      id: "sites",
      title: this._translocoService.translate("cantieri"),
      type: "basic",
      icon: "siteassistant:dashboard-cantieri",
      link: "/sites",
    },
    {
      id: "actors",
      title: this._translocoService.translate("attori"),
      type: "basic",
      icon: "siteassistant:dashboard-attori",
      link: "/actors",
    },
    {
      id: "resources",
      title: this._translocoService.translate("risorse"),
      type: "basic",
      icon: "siteassistant:dashboard-risorse",
      link: "/resources",
    },
  ];

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _translocoService: TranslocoService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    let nav: Navigation = {
      compact: cloneDeep(this.links),
      default: cloneDeep(this.links),
      futuristic: cloneDeep(this.links),
      horizontal: cloneDeep(this.links),
    };

    return of(nav).pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    );
  }
}

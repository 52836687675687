import * as Realm from "realm-web";

import { Observable, from } from "rxjs";

import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable()
export class RealmService {
  private app = new Realm.App({ id: environment.realmAppId }); ;
  constructor() {
    //this.app = new Realm.App({ id: environment.realmAppId });

    //this.app.emailPasswordAuth.confirmUser({token, tokenId})
  }

  get currentUser() {
    return this.app.currentUser;
  }

  get application() {

    return this.app;
  }

  get client() {
    return this.app.currentUser?.mongoClient("mongodb-atlas");
  }

  get appDb() {
    return this.app.currentUser?.mongoClient("mongodb-atlas").db(environment.realmDb);
  }

  get accountDb() {
    return this.app.currentUser?.mongoClient("mongodb-atlas").db(environment.realmAccounts);
  }

  signIn(credentials: { email: string; password: string }): Observable<any> {
    const realm_cred = Realm.Credentials.emailPassword(
      credentials.email,
      credentials.password
    );

    return from(this.app.logIn(realm_cred));
  }

  signUp(user: {
    email: string;
    password: string;
  }) {
    this.app.emailPasswordAuth.registerUser(user.email, user.password)
  }

  confirm(token: string, tokenId: string) {
    return this.app.emailPasswordAuth.confirmUser(token, tokenId);
  }

  sendResetPasswordEmail(email: string) {
    return this.app.emailPasswordAuth.sendResetPasswordEmail(email);
  }

  resetPassword(token: string, tokenId: string, password: string) {
    return this.app.emailPasswordAuth.resetPassword(token, tokenId, password);
  }
}
